import React, { useContext, useCallback, useMemo, useRef } from "react"
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import { CharacteristicTooltip, ClassBadge } from "components"
import { compareClasses } from "util/sortUtil"
import { isSystemGenerated } from "util/studentUtil"

import { AccountTypeContext } from "config/accountTypeContext"

export const SortableStudentsTable = props => {
  // Destructure props
  const {
    students,
    onRowClick,
    maxFriends,
    schoolCharacteristics,
    navToCharacteristics,
    studentRequestsEditable,
    teacherRequestsEditable,
    onAddRequestClick,
    surveyMode = false,
  } = props
  const gettextObj = useContext(AccountTypeContext).gettextObj

  // Define a sort function for the Friendship columns
  const sortFriendship = useCallback((rowA, rowB, columnId) => {
    const friend1 = rowA.original[columnId]
    const friend2 = rowB.original[columnId]

    // Sort by friend lastName's if they both exist
    if (friend1 && friend2) {
      if (friend1.studentTo && friend2.studentTo) {
        return friend1.studentTo.lastName.localeCompare(
          friend2.studentTo.lastName
        )
      }
    }

    return friend1 ? -1 : 1
  }, [])

  // Define a sort function for the Class columns
  const sortClass = useCallback((rowA, rowB, columnId) => {
    const classA = rowA.original[columnId]
    const classB = rowB.original[columnId]

    // Delegate the result to `compareClasses`
    if (classA && classB) {
      // Put the current grades on to the class objects so the `compareClasses`
      // util can do its job

      const classAClone = {
        grades: [rowA.original.currentGrade],
        ...classA,
      }

      const classBClone = {
        grades: [rowB.original.currentGrade],
        ...classB,
      }

      return compareClasses(
        rowA.original,
        classAClone,
        rowB.original,
        classBClone
      )
    } else {
      return 1
    }
  }, [])

  // Sort by the characteristic response label
  const sortCharacteristicResponses = useCallback((rowA, rowB, columnId) => {
    const studentResponseA = rowA.original[columnId]
    const studentResponseB = rowB.original[columnId]

    const responseLabelA = studentResponseA ? studentResponseA.label : ""
    const responseLabelB = studentResponseB ? studentResponseB.label : ""

    return responseLabelA.localeCompare(responseLabelB)
  }, [])

  // Helper function to define an scId in the data
  const scId = characteristic => `sc-${characteristic.id}`

  // Returns the length of the largest word. This is done
  // to deal with text wrapping in the cells
  const maxWordLength = cellValue => {
    return Math.max(...cellValue.split(/[\s-]/).map(word => word.length))
  }

  // Define and memoize the table's columns
  const columns = useMemo(() => {
    return [
      {
        header: "Student Details",
        meta: {
          className: "student-details",
        },
        columns: [
          surveyMode && {
            header: "",
            id: "index",
            cell: props => {
              return props.row.index + 1
            },
            meta: {
              cellClassName: `survey-cell index`,
            },
          },
          {
            header: "First Name",
            accessorKey: "firstName",
            meta: {
              cellClassName: `first-name-cell ${surveyMode ? "survey-cell" : ""}`,
              className: "first-name",
              widthEstimation: maxWordLength,
            },
          },
          {
            header: "Last Name",
            accessorKey: "lastName",
            meta: {
              cellClassName: `last-name-cell ${surveyMode ? "survey-cell" : ""}`,
              className: "last-name",
              widthEstimation: maxWordLength,
            },
          },
        ].filter(columnDef => !!columnDef),
      },
      {
        id: "info",
        columns: [
          {
            header: "ID",
            accessorKey: "studentCode",
            cell: StudentCodeCell,
            meta: {
              cellClassName: `${surveyMode ? "survey-cell" : ""}`,
            },
          },
          {
            header: "Gender",
            accessorKey: "gender",
            meta: {
              cellClassName: `gender-cell ${surveyMode ? "survey-cell" : ""}`,
              className: "text-center",
            },
          },
          {
            header: "Current Grade",
            accessorKey: "currentGrade",
            cell: props => props.getValue().label,
            enableSorting: false,
            meta: {
              cellClassName: `current-grade-cell text-center ${surveyMode ? "survey-cell" : ""}`,
              className: "text-center",
              widthEstimation: cellValue => `${cellValue?.label}`.length,
            },
          },
          {
            header: "Current Class",
            accessorKey: "currentClass",
            cell: ClassCell,
            sortingFn: sortClass,
            meta: {
              cellClassName: `current-class-cell ${surveyMode ? "survey-cell" : ""}`,
              className: "text-center",
              widthEstimation: cellValue => `${cellValue?.label}`.length,
            },
          },
          {
            header: `${gettextObj.gettext("New Grade")}`,
            accessorKey: "newGrade",
            cell: props => props.getValue().label,
            sortingFn: sortClass,
            meta: {
              cellClassName: `new-grade-cell text-center ${surveyMode ? "survey-cell" : ""}`,
              className: "text-center",
              widthEstimation: cellValue => `${cellValue?.label}`.length,
            },
          },
          !surveyMode && {
            header: `New ${gettextObj.gettext("Class")}`,
            accessorKey: "newClass",
            cell: ClassCell,
            sortingFn: sortClass,
            meta: {
              cellClassName: "new-class-cell",
              className: "text-center",
              widthEstimation: cellValue => `${cellValue?.label}`.length,
            },
          },
          {
            header: "Notes",
            accessorKey: "comments",
            meta: {
              cellClassName: "notes-cell",
            },
          },
        ].filter(columnDef => !!columnDef),
      },
      // Only render Requests column if they are allowed to edit requests
      (teacherRequestsEditable || studentRequestsEditable) && {
        header: "Requests",
        meta: {
          className: "total-column",
        },
        columns: [
          {
            header: "Total",
            accessorKey: "constraintsCount",
            cell: ConstraintRequestsCell,
            meta: {
              className: "total-column",
              cellClassName: "text-center",
              onAddRequestClick,
            },
          },
        ],
      },
      {
        header: () => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <span>Characteristics</span>
              <span>
                <i className="fa fa-cog" onClick={navToCharacteristics} />
              </span>
            </div>
          )
        },
        id: "characteristics-header",
        meta: {
          className: "characteristic-column",
        },
        columns: schoolCharacteristics.map(c => ({
          header: CharacteristicHeader,
          cell: CharacteristicCell,
          id: scId(c),
          accessorKey: scId(c),
          sortingFn: sortCharacteristicResponses,
          meta: {
            cellClassName: "text-center",
            className: "characteristic-column",
            characteristic: c,
            widthEstimation: cellValue => `${cellValue?.label}`.length,
            showDirectionIndicator: false,
          },
        })),
      },
      {
        header: "Friendships",
        meta: {
          className: "friendship-column",
        },
        columns: Array(maxFriends)
          .fill()
          .map((_value, index) => {
            return {
              header: `Friend ${index + 1}`,
              cell: FriendCell,
              accessorFn: row => row.friends[index],
              sortingFn: sortFriendship,
              meta: {
                className: "friendship-column",
                widthEstimation: cellValue => {
                  return [
                    cellValue?.studentTo?.firstName,
                    cellValue?.studentTo?.lastName,
                    cellValue?.studentTo?.currentClass?.label,
                  ]
                    .map(string => string ?? "")
                    .join("").length
                },
              },
            }
          }),
      },
    ].filter(columnDef => !!columnDef)
  }, [
    navToCharacteristics,
    maxFriends,
    sortClass,
    sortFriendship,
    schoolCharacteristics,
    sortCharacteristicResponses,
    gettextObj,
    onAddRequestClick,
    studentRequestsEditable,
    teacherRequestsEditable,
    surveyMode,
  ])

  // Memoize the student data passed in from props
  const studentData = useMemo(() => {
    // Add the characteristic responses per characteristic
    // This will make the table more efficient to deal with for sorting
    // later
    return students.map(student => {
      return schoolCharacteristics.reduce((acc, characteristic) => {
        const studentResponse = student.characteristicResponses.find(
          resp => resp.characteristicId === characteristic.id
        )
        return { ...acc, [scId(characteristic)]: studentResponse }
      }, student)
    })
  }, [students, schoolCharacteristics])

  // Set up the table
  const table = useReactTable({
    data: studentData,
    columns: columns,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnPinning: {
        left: ["index", "firstName", "lastName"],
      },
    },
  })

  const { rows } = table.getRowModel()

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    // Wrap all the functions in `useCallback` in an attempt to
    // not hit the maximum React render depth
    estimateSize: useCallback(() => 33, []), //estimate row height for accurate scrollbar dragging
    getScrollElement: useCallback(() => tableContainerRef.current, []),
    measureElement: useCallback(
      element => Math.round(element.getBoundingClientRect().height),
      []
    ),
    overscan: 20,
  })

  // For each column in the data, find the widest cell according
  // to the `widthEstimation` function in the meta for the column.
  //
  // The `widthEstimation` function will take the cell value from `getValue()`
  // and return a width estimate for the cell. This will then be used to determine
  // which cell is the widest.
  //
  // NOTE: Ideally we should give the props from cell.getContext() to the function
  // because it contains more information and is what is given to the `cell` during
  // render. However, accessing it is much much slower because the cell value is cached
  // (0ms compared to 0.01ms) and we access it a lot so it causes a big delay
  // for tables with a lot of cells.
  //
  // This is not going to be 100% accurate but it should cover most schools
  const visibleColumns = table.getVisibleLeafColumns()

  const widestCells = React.useMemo(() => {
    // By default we just use the length of the value
    const defaultWidthEstimation = cellValue => {
      return `${cellValue ? cellValue : ""}`.length
    }

    return visibleColumns.map(column => {
      if (rows.length === 0) return null
      const colIndex = column.getIndex()
      const widthEstimation =
        column.columnDef?.meta?.widthEstimation ?? defaultWidthEstimation

      const rowWithWidestCell = rows.reduce((acc, row) => {
        const rowCellLength = widthEstimation(row.getValue(column.id))
        const accCellLength = widthEstimation(acc.getValue(column.id))

        if (rowCellLength > accCellLength) {
          return row
        } else {
          return acc
        }
      })

      return rowWithWidestCell.getVisibleCells()[colIndex]
    })
  }, [rows, visibleColumns])

  const virtualItems = rowVirtualizer.getVirtualItems()

  // Position what rows to show by putting top and bottom padding rows
  // to push the rows to the correct position. We don't want to
  // use `position: absolute` because it doesn't work properly with
  // table rows. See https://github.com/TanStack/virtual/discussions/476#discussioncomment-4724139
  const [paddingTop, paddingBottom] =
    virtualItems.length > 0
      ? [
          Math.max(
            0,
            virtualItems[0].start - rowVirtualizer.options.scrollMargin
          ),
          Math.max(
            0,
            rowVirtualizer.getTotalSize() -
              virtualItems[virtualItems.length - 1].end
          ),
        ]
      : [0, 0]

  // For each pinned column, we want to find it's offset and then use CSS variables
  // to let all the cells in that column know what its left offset is
  // This allows us to have dynamic sticky columns without explicitly setting the
  // width.
  const setPinnedColumnOffset = (element, header) => {
    if (element) {
      const elementLeft = `${element.getBoundingClientRect().x}px`

      // Set the offset for this column
      element.style.left = elementLeft

      const key = `--column-${header.id}-left`
      const tableElement = element.closest("table")

      if (tableElement.style.getPropertyValue(key) !== elementLeft) {
        tableElement.style.setProperty(key, elementLeft)
      }
    }
  }

  const pinnedClassName = isPinned => (isPinned ? "sticky-column" : "")

  return (
    <div className="students-sortable-table" ref={tableContainerRef}>
      <table cellSpacing="0">
        <thead className={students.length === 0 ? "no-data" : ""}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const isPinned = header.column.getIsPinned()
                return (
                  <th
                    ref={
                      isPinned
                        ? element => setPinnedColumnOffset(element, header)
                        : null
                    }
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`${header.column.columnDef?.meta?.className || ""} ${header.column.getCanSort() ? "cursor-pointer select-none" : ""} ${pinnedClassName(isPinned)}`}
                    onClick={header.column.getToggleSortingHandler()}
                    title={
                      header.column.getCanSort()
                        ? header.column.getNextSortingOrder() === "asc"
                          ? "Sort ascending"
                          : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                        : undefined
                    }>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {/* Add a sort direction indicator */}
                    {(header.column.columnDef?.meta?.showDirectionIndicator ??
                    true) ? (
                      <DirectionIndicator
                        isSorted={header.column.getIsSorted()}
                      />
                    ) : null}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        {students.length === 0 && (
          <tbody>
            <tr className="empty-row">
              <td className="empty-data-cell" colSpan="100">
                There are no students matching this filter. Try selecting a
                different filter from the ‘Filter’ dropdown above.
              </td>
            </tr>
          </tbody>
        )}

        {students.length > 0 && (
          <tbody
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
            }}>
            {/* Add padding row to position the rows into place */}
            {paddingTop > 0 && (
              <tr>
                <td colSpan={visibleColumns.length} style={{ paddingTop }} />
              </tr>
            )}

            {/* Add a collapsed row which contains the widest cells per column in the data to avoid
              the column width from shifting while scrolling */}
            <tr style={{ visibility: "collapse" }}>
              {widestCells.map(cell => {
                return (
                  <td
                    key={`padding-${cell.id}`}
                    className={cell.column.columnDef?.meta?.cellClassName}>
                    >{flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>

            {virtualItems.map(virtualRow => {
              const row = rows[virtualRow.index]

              // Handle if this particular row is clicked
              // We can't use `row.values` because the student ID is not shown on the
              // table (hence not resolved)
              const handleClick = () => onRowClick(row.original.id)

              return (
                <tr
                  key={row.id}
                  data-index={virtualRow.index}
                  ref={rowVirtualizer.measureElement}
                  onClick={handleClick}>
                  {row.getVisibleCells().map(cell => {
                    const isPinned = cell.column.getIsPinned()
                    return (
                      <td
                        key={cell.id}
                        style={{
                          left: isPinned
                            ? `var(--column-${cell.column.id}-left)`
                            : "auto",
                        }}
                        className={`${cell.column.columnDef?.meta?.cellClassName || ""} ${virtualRow.index % 2 === 0 ? "students-sortable-table__even" : "students-sortable-table__odd"} ${pinnedClassName(isPinned)}`}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {paddingBottom > 0 && (
              <tr>
                <td colSpan={visibleColumns.length} style={{ paddingBottom }} />
              </tr>
            )}
          </tbody>
        )}
      </table>
    </div>
  )
}

const DirectionIndicator = ({ isSorted }) => {
  return (
    <span>
      {{
        asc: " ↑",
        desc: " ↓",
      }[isSorted] ?? null}
    </span>
  )
}

const ClassCell = props => {
  const value = props.getValue()

  return value ? <ClassBadge className="mx-auto" label={value.label} /> : null
}

const CharacteristicHeader = props => {
  const characteristic = props.column.columnDef.meta.characteristic

  return (
    <div className="d-flex justify-content-between align-items-center w-100 pr-1">
      <div>
        <span className="font-bold">{characteristic.name}</span>
        <DirectionIndicator isSorted={props.column.getIsSorted()} />
      </div>
      <i
        className="fa fa-info-circle font-size-14 u-opacity-50 pl-2"
        data-tooltip-id={`characteristic-${characteristic.id}`}
      />
      <CharacteristicTooltip
        name={characteristic.name}
        id={`characteristic-${characteristic.id}`}
        place="right"
        variant="light"
        characteristicResponses={characteristic.characteristicResponses}
      />
    </div>
  )
}

const ConstraintRequestsCell = props => {
  const value = props.getValue()

  // TODO: onAddRequestClick does exactly the same thing as the row click at the moemnt
  // so we won't bother using it just yet
  // Handle if the button is clicked
  // const handleClick = () => onAddRequestClick(props.getValue().studentCode)
  return (
    <span className="add-button">
      {value}
      <i className="ml-2 fa fa-plus" />
    </span>
  )
}

const CharacteristicCell = props => {
  const studentResponse = props.getValue()
  return studentResponse ? studentResponse.label : ""
}

const StudentCodeCell = props => {
  const studentCode = props.getValue()
  return (
    <div
      className={
        studentCode && isSystemGenerated(studentCode) ? "generated" : ""
      }>
      {studentCode}
    </div>
  )
}

const FriendCell = props => {
  const value = props.getValue()

  // Render the friend if they exist, otherwise render nothing
  if (value) {
    return (
      <div className="friend-cell">
        <div className="d-flex justify-content-between align-items-center">
          <span className="friends-name">
            {value.studentTo.firstName} {value.studentTo.lastName}
          </span>

          {value.studentTo.currentClass && (
            <ClassBadge label={value.studentTo.currentClass.label} />
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}
