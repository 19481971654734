import React, { Component } from "react"
import { Navigate } from "react-router-dom"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import {
  schoolWithSisSuperQuery,
  createOrUpdateUserMutation,
  schoolSettingsQuery,
  updateSchoolSettingMutation,
  createOrUpdateSchoolMutation,
  deleteSchoolMutation,
  deleteSchoolDataMutation,
  rollOverData,
} from "domains/accountSettings/graphql"
import { deactivateAllStudentsMutation } from "domains/students/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import { requestQuery } from "domains/requests/graphql"
import { getNotificationsQuery } from "domains/notifications/graphql"

import { Loader } from "components"
import { SuperAdmin } from "domains/superAdmin/components"

import { getSchoolId } from "util/app"
import { isPrivilegedRole } from "util/userUtil"

class SuperAdminPageComponent extends Component {
  render() {
    const {
      loadingPreferences,
      loadingSchool,
      myprofile,
      createOrUpdateUserMutation,
      updateSchoolSettingMutation,
      deleteSchoolMutation,
      deleteSchoolDataMutation,
      schoolSettings,
      school,
      rollOverData,
      deactivateAllStudentsMutation,
      createOrUpdateSchoolMutation,
    } = this.props

    if (!isPrivilegedRole(myprofile.role)) return <Navigate to="/" replace />
    if (loadingPreferences || loadingSchool) return <Loader />

    return (
      <SuperAdmin
        myprofile={myprofile}
        createOrUpdateUserMutation={createOrUpdateUserMutation}
        updateSchoolSettingMutation={updateSchoolSettingMutation}
        createOrUpdateSchoolMutation={createOrUpdateSchoolMutation}
        schoolSettings={schoolSettings}
        schoolSettingsQuery={schoolSettingsQuery}
        school={school}
        deleteSchoolDataMutation={deleteSchoolDataMutation}
        deleteSchoolMutation={deleteSchoolMutation}
        rollOverData={rollOverData}
        deactivateAllStudentsMutation={deactivateAllStudentsMutation}
      />
    )
  }
}

export const SuperAdminPage = compose(
  graphql(userProfileQuery, {
    props: ({ data: { myprofile } }) => ({
      myprofile,
    }),
  }),
  graphql(createOrUpdateUserMutation, { name: "createOrUpdateUserMutation" }),
  graphql(schoolSettingsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { schoolSettings, loading } }) => ({
      loadingPreferences: loading,
      schoolSettings,
    }),
  }),
  graphql(updateSchoolSettingMutation, { name: "updateSchoolSettingMutation" }),
  graphql(createOrUpdateSchoolMutation, {
    name: "createOrUpdateSchoolMutation",
  }),
  graphql(schoolWithSisSuperQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { school, loading } }) => ({
      loadingSchool: loading,
      school,
    }),
  }),
  graphql(deleteSchoolMutation, { name: "deleteSchoolMutation" }),
  graphql(deleteSchoolDataMutation, { name: "deleteSchoolDataMutation" }),
  graphql(rollOverData, {
    name: "rollOverData",
    options: () => {
      return {
        variables: { schoolId: getSchoolId() },
        refetchQueries: [
          {
            query: requestQuery,
            variables: {
              adminOnly: true,
              schoolId: getSchoolId(),
            },
          },
          {
            query: getNotificationsQuery,
            variables: { schoolId: getSchoolId() },
          },
        ],
      }
    },
  }),
  graphql(deactivateAllStudentsMutation, {
    name: "deactivateAllStudentsMutation",
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
  })
)(SuperAdminPageComponent)
