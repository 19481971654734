import React, { useState } from "react"

import { useMutation } from "@apollo/client"

import {
  schoolWithSisQuery,
  createOrUpdateSisConfigPowerschoolMutation,
  deleteSisConfigPowerschoolMutation,
} from "domains/accountSettings/graphql"

import powerschoolPlugin from "templateFiles/powerschool_plugin.xml"

import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap"
import { ButtonBar, ModalHeader, InlineError, CautionModal } from "components"
import { POWERSCHOOL_INTEGRATION_INSTRUCTIONS } from "domains/app/constants/documentLinks"
import { HOME_ROOM, SECTION_ENROLLMENT } from "../constants"

export const PowerschoolModal = ({
  school: { id: schoolId, powerschoolSisConfig: config },
  toggle,
}) => {
  const [edit, setEdit] = useState(false)
  const clientId = config ? config.clientId : null
  const clientSecret = config ? config.clientSecret : null
  const apiEndpoint = config ? config.apiEndpoint : null
  const powerschoolSchoolId = config ? config.powerschoolSchoolId : null
  const useHomeRoomAttribute = config
    ? config.currentClassOption === HOME_ROOM
    : false

  return (
    <Modal isOpen className="modal-dialog-centered">
      <ModalHeader
        title="Set up Powerschool Integration"
        className="px-5 py-4"
        toggle={toggle}
      />
      <ModalBody className="px-5 py-4">
        <p>
          Please see detailed instructions available{" "}
          <a
            href={POWERSCHOOL_INTEGRATION_INSTRUCTIONS}
            target="_blank"
            rel="noopener noreferrer">
            here
          </a>
          .
        </p>
        <p>Download and install the following plugin into Power School</p>
        <a
          href={powerschoolPlugin}
          download="Class_Solver_PowerSchool_Plugin.xml">
          <Button color="primary m-2">Download PowerSchool Plugin</Button>
        </a>
        <p className="py-2">
          After installing plugin, please enter the Client ID and Client Secret
        </p>
        {edit ? (
          <EditPowerschoolConfig
            schoolId={schoolId}
            clientId={clientId}
            clientSecret={clientSecret}
            apiEndpoint={apiEndpoint}
            setEdit={setEdit}
            powerschoolSchoolId={powerschoolSchoolId}
            useHomeRoomAttribute={useHomeRoomAttribute}
          />
        ) : (
          <PowerschoolConfig
            schoolId={schoolId}
            apiEndpoint={apiEndpoint}
            clientId={clientId}
            clientSecret={clientSecret}
            setEdit={setEdit}
            powerschoolSchoolId={powerschoolSchoolId}
            useHomeRoomAttribute={useHomeRoomAttribute}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

const PowerschoolConfig = ({
  schoolId,
  apiEndpoint,
  clientId,
  clientSecret,
  setEdit,
  powerschoolSchoolId,
  useHomeRoomAttribute,
}) => {
  const [showRemoveConfigModal, setShowRemoveConfigModal] = useState(false)

  const [deleteSisConfigPowerschool] = useMutation(
    deleteSisConfigPowerschoolMutation,
    {
      variables: { schoolId },
      onCompleted: () => setShowRemoveConfigModal(false),
      refetchQueries: [
        {
          query: schoolWithSisQuery,
          variables: { schoolId },
        },
      ],
    }
  )

  const onRemoveClick = () => setShowRemoveConfigModal(true)

  return (
    <>
      <Row>
        <div className="col sm-6">
          <div>
            <b>PowerSchool URL</b>
          </div>
          <p>{apiEndpoint || "Not configured"}</p>
        </div>
        <div className="col sm-6">
          <Label>
            <b>School DCID in PowerSchool</b>
          </Label>
          <p>{powerschoolSchoolId || "Not configured"}</p>
        </div>
      </Row>
      <Row>
        <div className="col sm-6">
          <div>
            <b>Client ID</b>
          </div>
          <p>{clientId || "Not configured"}</p>
        </div>

        <div className="col sm-6">
          <div>
            <b>Client Secret</b>
          </div>
          <p>{clientSecret || "Not configured"}</p>
        </div>
      </Row>
      <Row>
        <FormGroup className="col sm-6">
          <Label className="pl-4" style={{ cursor: "default" }} check>
            <Input
              disabled
              type="checkbox"
              name="clientId"
              checked={useHomeRoomAttribute}
            />
            Use the <code>home_room</code> attribute in Powerschool as current
            class
          </Label>
        </FormGroup>
      </Row>
      <div className="d-flex justify-content-between">
        <span
          className="text-primary font-size-14 mr-4 cursor-pointer"
          onClick={() => setEdit(true)}>
          Edit
        </span>

        {powerschoolSchoolId && (
          <span
            className="text-primary font-size-14 mr-4 cursor-pointer"
            onClick={onRemoveClick}>
            Remove Powerschool Integration
          </span>
        )}
      </div>

      {showRemoveConfigModal && (
        <CautionModal
          toggle={() => setShowRemoveConfigModal(false)}
          heading="Remove Powerschool configuration?"
          text="Are you sure you want to remove the Powerschool integration for this school?"
          buttonText="Yes"
          onButtonClick={deleteSisConfigPowerschool}
        />
      )}
    </>
  )
}

const EditPowerschoolConfig = ({
  schoolId,
  apiEndpoint,
  clientId,
  clientSecret,
  setEdit,
  powerschoolSchoolId,
  useHomeRoomAttribute,
}) => {
  const [createOrUpdateSisConfigPowerschool] = useMutation(
    createOrUpdateSisConfigPowerschoolMutation,
    {
      refetchQueries: [
        {
          query: schoolWithSisQuery,
          variables: { schoolId },
        },
      ],
    }
  )

  const [errors, setErrors] = useState("")
  const [newClientId, setNewClientId] = useState(clientId || "")
  const [newClientSecret, setNewClientSecret] = useState(clientSecret || "")
  const [newApiEndpoint, setNewApiEndpoint] = useState(apiEndpoint || "")
  const [newPowerschoolSchoolId, setNewPowerschoolSchoolId] = useState(
    powerschoolSchoolId || ""
  )
  const [newUseHomeRoomAttribute, setNewUseHomeRoomAttribute] = useState(
    useHomeRoomAttribute || false
  )

  const onClientIdChange = e => setNewClientId(e.target.value)
  const onClientSecretChange = e => setNewClientSecret(e.target.value)
  const onApiEndpointChange = e => setNewApiEndpoint(e.target.value)
  const onPowerschoolSchoolIdChange = e =>
    setNewPowerschoolSchoolId(e.target.value)
  const onHomeRoomAttributeChange = e => {
    setNewUseHomeRoomAttribute(e.target.checked)
  }

  const onSave = () => {
    const variables = {
      schoolId: schoolId,
      config: {
        clientId: newClientId,
        clientSecret: newClientSecret,
        powerschoolSchoolId: newPowerschoolSchoolId,
        apiEndpoint: newApiEndpoint,
        currentClassOption: newUseHomeRoomAttribute
          ? HOME_ROOM
          : SECTION_ENROLLMENT,
      },
    }

    createOrUpdateSisConfigPowerschool({
      variables,
    })
      .then(() => {
        setEdit(false)
      })
      .catch(() => {
        setErrors("Error saving configuration")
      })
  }

  return (
    <div>
      <Row>
        <FormGroup className="col sm-6">
          <Label>
            <b>PowerSchool URL</b>
          </Label>
          <Input
            type="text"
            name="apiEndpoint"
            onChange={onApiEndpointChange}
            value={newApiEndpoint}
          />
          {newApiEndpoint === "" && <InlineError text="Cannot be blank" />}
        </FormGroup>
        <FormGroup className="col sm-6">
          <Label>
            <b>School DCID in PowerSchool</b>
          </Label>
          <Input
            type="text"
            name="powerschoolSchoolId"
            onChange={onPowerschoolSchoolIdChange}
            value={newPowerschoolSchoolId}
          />
          {newPowerschoolSchoolId === "" && (
            <InlineError text="Cannot be blank" />
          )}
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col sm-6">
          <Label>
            <b>Client ID</b>
          </Label>
          <Input
            type="text"
            name="clientId"
            onChange={onClientIdChange}
            value={newClientId}
          />
          {newClientId === "" && <InlineError text="Cannot be blank" />}
        </FormGroup>
        <FormGroup className="col sm-6">
          <Label>
            <b>Client Secret</b>
          </Label>
          <Input
            type="text"
            name="clientSecret"
            onChange={onClientSecretChange}
            value={newClientSecret}
          />
          {newClientSecret === "" && <InlineError text="Cannot be blank" />}
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col sm-6">
          <Label className="pl-4" check>
            <Input
              type="checkbox"
              name="clientId"
              onChange={onHomeRoomAttributeChange}
              checked={newUseHomeRoomAttribute}
            />
            Use the <code>home_room</code> attribute in Powerschool as current
            class
          </Label>
        </FormGroup>
      </Row>
      <ButtonBar
        onCancelClick={() => setEdit(false)}
        buttonText="Save Configuration"
        onButtonClick={onSave}
      />
      <InlineError text={errors} />
    </div>
  )
}
