import React from "react"
import { useAuth0 } from "../auth0Wrapper"
import queryString from "query-string"
import { CautionModal } from "components"
import { getApiRegion } from "util/apiUtil"
import { useLocation } from "react-router-dom"
import { useApolloClient } from "@apollo/client"

const Logout = () => {
  const { logout } = useAuth0()
  const apolloClient = useApolloClient()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const doLogout = () => {
    const federated = queryParams.federated === "true"
    const returnTo = window.location.origin + "/Login?country=" + getApiRegion()

    localStorage.clear()
    sessionStorage.clear()
    apolloClient.resetStore()
    logout({ logoutParams: { returnTo, federated } })
  }

  return (
    <React.Fragment>
      {queryParams.expired === "true" ? (
        <CautionModal
          isOpen
          onButtonClick={doLogout}
          buttonText="Dismiss"
          showCancel={false}
          heading="Session Expired"
          text={
            <div>
              Your login session has expired or the school requires Multi-Factor
              Authentication (MFA) and you do not have it activated, please log
              in again
              <br />
              <br />
              If you see this error again, please contact your school
              administrator.
            </div>
          }
        />
      ) : (
        doLogout()
      )}
    </React.Fragment>
  )
}

export const LogoutPage = Logout
