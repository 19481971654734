import React, { useState } from "react"
import Select from "react-select"

import { useQuery, useMutation } from "@apollo/client"

import { Modal, ModalBody, FormGroup, Label, Row } from "reactstrap"
import { ButtonBar, ModalHeader, InlineError, CautionModal } from "components"
import {
  schoolWithSisQuery,
  getCleverDistrictsQuery,
  getCleverSchoolsForDistrictQuery,
  createOrUpdateSisConfigCleverMutation,
  deleteSisConfigCleverMutation,
} from "domains/accountSettings/graphql"

import { CLEVER_INTEGRATION_INSTRUCTIONS } from "domains/app/constants/documentLinks"

const transformDistrictForSelect = district => {
  if (district) {
    return {
      value: district.id,
      label: `${district.name} - ${district.id}`,
    }
  }
}

const transformSchoolForSelect = school => {
  if (school) {
    return {
      value: school.id,
      label: `${school.schoolNumber} - ${school.name} - ${school.id}`,
    }
  }
}

export const CleverModal = ({
  school: { id: schoolId, cleverSisConfig: config },
  toggle,
}) => {
  const [edit, setEdit] = useState(false)
  const cleverDistrictId = config ? config.cleverDistrictId : null
  const cleverSchoolId = config ? config.cleverSchoolId : null

  const {
    data: districtData,
    //    loading: loadingDistrict,
    //    error: districtError,
  } = useQuery(getCleverDistrictsQuery, { fetchPolicy: "network-only" })

  const {
    data: schoolData,
    //    loading: loadingSchool,
    //    error: schoolError,
  } = useQuery(getCleverSchoolsForDistrictQuery, {
    skip: !cleverDistrictId,
    variables: { districtId: cleverDistrictId },
    fetchPolicy: "network-only",
  })

  const districts = districtData?.getCleverDistricts || []
  const schools = schoolData?.getCleverSchoolsForDistrict || []

  const cleverDistrictName = districts.find(
    district => district.id === cleverDistrictId
  )?.name

  const cleverSchoolName = schools.find(
    school => school.id === cleverSchoolId
  )?.name

  return (
    <Modal isOpen className="modal-dialog-centered">
      <ModalHeader
        title="Configure Clever Integration"
        className="px-5 py-4"
        toggle={toggle}
      />
      <ModalBody className="px-5 py-4">
        <p>
          Please see detailed instructions available{" "}
          <a
            href={CLEVER_INTEGRATION_INSTRUCTIONS}
            target="_blank"
            rel="noopener noreferrer">
            here
          </a>
          .
        </p>
        {edit ? (
          <EditCleverConfig
            schoolId={schoolId}
            setEdit={setEdit}
            cleverSchoolId={cleverSchoolId}
            cleverDistrictId={cleverDistrictId}
          />
        ) : (
          <CleverConfig
            schoolId={schoolId}
            setEdit={setEdit}
            cleverSchoolId={cleverSchoolId}
            cleverDistrictId={cleverDistrictId}
            cleverSchoolName={cleverSchoolName}
            cleverDistrictName={cleverDistrictName}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

const CleverConfig = ({
  schoolId,
  setEdit,
  cleverSchoolId,
  cleverDistrictId,
  cleverSchoolName,
  cleverDistrictName,
}) => {
  const [showRemoveConfigModal, setShowRemoveConfigModal] = useState(false)

  const [deleteSisConfigClever] = useMutation(deleteSisConfigCleverMutation, {
    variables: { schoolId },
    onCompleted: () => setShowRemoveConfigModal(false),
    refetchQueries: [
      {
        query: schoolWithSisQuery,
        variables: { schoolId },
      },
    ],
  })

  const onRemoveClick = () => setShowRemoveConfigModal(true)

  return (
    <>
      <Row>
        <div className="col sm-6">
          <Label>
            <b>District Clever ID</b>
          </Label>
          <p>{cleverDistrictId || "Not configured"}</p>
        </div>
        <div className="col sm-6">
          <Label>
            <b>District Name</b>
          </Label>
          <p>
            {cleverDistrictName ||
              (cleverDistrictId ? "Unknown" : "Not configured")}
          </p>
        </div>
      </Row>
      <Row>
        <div className="col sm-6">
          <Label>
            <b>School Clever ID</b>
          </Label>
          <p>{cleverSchoolId || "Not configured"}</p>
        </div>
        <div className="col sm-6">
          <Label>
            <b>School Name</b>
          </Label>
          <p>
            {cleverSchoolName ||
              (cleverSchoolId ? "Unknown" : "Not configured")}
          </p>
        </div>
      </Row>
      <div className="d-flex justify-content-between">
        <span
          className="text-primary font-size-14 mr-4 cursor-pointer"
          onClick={() => setEdit(true)}>
          Choose Clever School
        </span>

        {cleverSchoolId && (
          <span
            className="text-primary font-size-14 mr-4 cursor-pointer"
            onClick={onRemoveClick}>
            Remove Clever Integration
          </span>
        )}
      </div>

      {showRemoveConfigModal && (
        <CautionModal
          toggle={() => setShowRemoveConfigModal(false)}
          heading="Remove Clever configuration?"
          text="Are you sure you want to remove the Clever integration for this school?"
          buttonText="Yes"
          onButtonClick={deleteSisConfigClever}
        />
      )}
    </>
  )
}

const EditCleverConfig = ({
  schoolId,
  setEdit,
  cleverSchoolId,
  cleverDistrictId,
}) => {
  const [createOrUpdateSisConfigClever] = useMutation(
    createOrUpdateSisConfigCleverMutation,
    {
      refetchQueries: [
        {
          query: schoolWithSisQuery,
          variables: { schoolId },
        },
      ],
    }
  )

  const [errors, setErrors] = useState("")
  const [newCleverDistrictId, setNewCleverDistrictId] = useState(
    cleverDistrictId || ""
  )
  const [newCleverSchoolId, setNewCleverSchoolId] = useState(
    cleverSchoolId || ""
  )

  const {
    data: districtData,
    //    loading: loadingDistrict,
    //    error: districtError,
  } = useQuery(getCleverDistrictsQuery, { fetchPolicy: "network-only" })

  const {
    data: schoolData,
    //    loading: loadingSchool,
    //    error: schoolError,
  } = useQuery(getCleverSchoolsForDistrictQuery, {
    skip: !newCleverDistrictId,
    variables: { districtId: newCleverDistrictId },
    fetchPolicy: "network-only",
  })

  const districts = districtData?.getCleverDistricts || []
  const schools = schoolData?.getCleverSchoolsForDistrict || []

  const onCleverSchoolIdChange = e => setNewCleverSchoolId(e.value)
  const onCleverDistrictIdChange = e => setNewCleverDistrictId(e.value)

  const onSave = () => {
    const variables = {
      schoolId: schoolId,
      config: {
        cleverSchoolId: newCleverSchoolId,
        cleverDistrictId: newCleverDistrictId,
      },
    }

    createOrUpdateSisConfigClever({
      variables,
    })
      .then(() => {
        setEdit(false)
      })
      .catch(() => {
        setErrors("Error saving configuration")
      })
  }

  return (
    <div>
      <Row>
        <FormGroup className="col sm-6">
          <Label>
            <b>Choose District in Clever</b>
          </Label>
          <Select
            options={districts.map(transformDistrictForSelect)}
            onChange={onCleverDistrictIdChange}
            value={transformDistrictForSelect(
              districts.find(district => district.id === newCleverDistrictId)
            )}
            isDisabled={districts.length === 0}
          />
          {newCleverDistrictId === "" && <InlineError text="Cannot be blank" />}
        </FormGroup>
        <FormGroup className="col sm-6">
          <Label>
            <b>Choose School in Clever</b>
          </Label>
          <Select
            options={schools.map(transformSchoolForSelect)}
            onChange={onCleverSchoolIdChange}
            value={transformSchoolForSelect(
              schools.find(school => school.id === newCleverSchoolId)
            )}
            isDisabled={schools.length === 0}
          />
          {newCleverSchoolId === "" && <InlineError text="Cannot be blank" />}
        </FormGroup>
      </Row>
      <ButtonBar
        onCancelClick={() => setEdit(false)}
        buttonText="Save Configuration"
        onButtonClick={onSave}
      />
      <InlineError text={errors} />
    </div>
  )
}
