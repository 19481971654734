import { useAuth0 } from "../auth0Wrapper"
import queryString from "query-string"
import { useParams, useLocation, Navigate } from "react-router-dom"
import { getApiRegion, setApiRegion, isValidRegion } from "util/apiUtil"

export const CleverCallbackPage = () => {
  const params = useParams()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const { loginWithRedirect } = useAuth0()

  const country = params.country

  if (!isValidRegion(country)) return null

  if (country !== getApiRegion()) {
    setApiRegion(country)
    // reload entire page to make sure we are talking to the correct Auth0 instance
    window.location.reload()
    return null
  } else {
    if (queryParams.code) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin,
          connection: "Clever",
        },
      })
    } else {
      return <Navigate to="/" />
    }
  }

  return null
}
