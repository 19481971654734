import React, { useState, useEffect, useContext } from "react"
import { createAuth0Client } from "@auth0/auth0-spa-js"

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)

const Auth0OnlineProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  audience,
  leeway,
  clientId,
  domain,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [authenticationError, setAuthenticationError] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        clientId,
        domain,
        leeway,
        authorizationParams: {
          audience,
          // Allow user to select which Google/Microsoft account to log in with
          // See https://stackoverflow.com/questions/40052501/how-to-force-account-selection-when-using-social-provider-login-in-lock
          prompt: "select_account",
        },
      })

      setAuth0(auth0FromHook)

      if (window.location.search.includes("error=")) {
        setAuthenticationError({ message: "auth0_error" })
      }

      if (
        window.location.search.includes("code=") &&
        !window.location.search.includes("handle=false")
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback()
          onRedirectCallback(appState)
        } catch (e) {
          setAuthenticationError(e)
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        authenticationError,
        setAuthenticationError,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}>
      {children}
    </Auth0Context.Provider>
  )
}

// An offline provider which is only used in the standalone version
const Auth0OfflineProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(isAuthenticated => {
    const initAuth0 = () => {
      if (isAuthenticated) {
        setUser({
          given_name: "Peter",
          family_name: "Parker",
          nickname: "Spidey",
          name: "Peter Parker",
          picture: "",
          locale: "en",
          updated_at: "2019-07-04T06:41:25.325Z",
          email: "foo@example.com",
          email_verified: true,
          sub: "auth0|1111111",
        })
      }
      setLoading(false)
    }
    initAuth0()
  }, [])

  const loginWithRedirect = () => setIsAuthenticated(true)

  const getTokenSilently = () => {
    return new Promise(resolve => {
      resolve("random_token")
    })
  }

  const logout = ({ returnTo }) => {
    setUser(null)
    setIsAuthenticated(false)
    setLoading(false)
    window.location.href = returnTo
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        loginWithRedirect,
        getTokenSilently,
        logout,
      }}>
      {children}
    </Auth0Context.Provider>
  )
}

export const Auth0Provider =
  process.env.REACT_APP_STANDALONE === "true"
    ? Auth0OfflineProvider
    : Auth0OnlineProvider
