import React, { useState } from "react"
import { graphql } from "@apollo/client/react/hoc"
import { useQuery, useApolloClient } from "@apollo/client"
import compose from "lodash.flowright"
import {
  studentsQuery,
  createOrUpdateInactiveStudentMutation,
  bulkUploadStudentsMutation,
  studentFlagsClientQuery,
  uiStudentPageQuery,
} from "domains/students/graphql"
import {
  schoolSettingsQuery,
  schoolWithSisQuery,
  settingsClientQuery,
} from "domains/accountSettings/graphql"
import {
  activeCurrentClassesQuery,
  newClassesQuery,
  currentSchoolGradesQuery,
  newSchoolGradesQuery,
  schoolGradesWithSolutionsQuery,
} from "domains/classes/graphql"
import { characteristicsQuery } from "domains/characteristics/graphql"

import { Loader } from "components"
import { Students } from "domains/students/components"

import { handleSessionExpired, getSchoolId, queriesAreLoading } from "util/app"
import { schoolUploadsQuery } from "domains/upload/graphql"
import { useBulkUploadJob } from "domains/upload/hooks/useBulkUploadJob"

import { userProfileQuery } from "domains/auth/graphql"
import { dashboardQuery } from "domains/dashboard/graphql"
import { pendingImport } from "../pendingImport"

import queryString from "query-string"

import { useNavigate, useLocation, Navigate } from "react-router-dom"

const StudentsPageComponent = props => {
  const {
    activeCurrentClassesQuery: { activeCurrentClasses: currentClasses },
    bulkUploadStudentsMutation,
    characteristicsQuery: { schoolCharacteristics },
    createOrUpdateInactiveStudentMutation,
    currentSchoolGradesQuery: { currentSchoolGrades },
    dashboardQuery: { dashboard },
    newClassesQuery: { newClasses },
    newSchoolGradesQuery: { newSchoolGrades },
    schoolSettingsQuery: { schoolSettings },
    schoolGradesWithSolutionsQuery: { schoolGradesWithSolutions },
    schoolUploadsQuery,
    schoolWithSisQuery: { school },
    settings,
    studentFlags,
    studentsQuery: { students: studentsData },
    userProfileQuery: { myprofile: user },
  } = props

  const [jobId, setJobId] = useState()

  const {
    cancel: [cancelUploadJob],
  } = useBulkUploadJob(jobId)

  const navigate = useNavigate()
  const location = useLocation()
  const client = useApolloClient()
  const { data } = useQuery(uiStudentPageQuery)
  const queryParams = queryString.parse(location.search)

  // Store the filterId from the params into Apollo state so
  // we remember where we were next time we come back to this page
  if (queryParams.filterId) {
    client.writeQuery({
      query: uiStudentPageQuery,
      data: {
        studentPage: {
          __typename: "studentPage",
          filterId: queryParams.filterId,
        },
      },
    })
  }

  handleSessionExpired(characteristicsQuery.error)

  if (queriesAreLoading(props)) return <Loader />

  const { job: pendingStudentJob, error: pendingStudentJobError } =
    pendingImport(schoolUploadsQuery, "STUDENT")

  if (pendingStudentJob && pendingStudentJobError) {
    if (!jobId) {
      setJobId(pendingStudentJob.id)
      return <Loader />
    }

    cancelUploadJob()
  } else if (pendingStudentJob) {
    return <Navigate to="/Students/Upload/Columns" replace />
  }

  const {
    job: pendingStudentCharacteristicJob,
    error: pendingStudentCharacteristicJobError,
  } = pendingImport(schoolUploadsQuery, "STUDENT_CHARACTERISTIC")

  if (pendingStudentCharacteristicJob && pendingStudentCharacteristicJobError) {
    if (!jobId) {
      setJobId(pendingStudentCharacteristicJob.id)
      return <Loader />
    }

    cancelUploadJob()
  } else if (pendingStudentCharacteristicJob) {
    const jobId = pendingStudentCharacteristicJob.id
    return (
      <Navigate
        to="/Students/Characteristics/MapColumns"
        state={{ jobId }}
        replace
      />
    )
  }

  const setFilterId = filterId => {
    const search = new URLSearchParams({ filterId }).toString()
    navigate({ search })
  }

  const selectedFilterId = (data && data.studentPage.filterId) || null

  return (
    <Students
      bulkUploadStudentsMutation={bulkUploadStudentsMutation}
      createOrUpdateInactiveStudentMutation={
        createOrUpdateInactiveStudentMutation
      }
      currentClasses={currentClasses}
      currentSchoolGrades={currentSchoolGrades}
      dashboard={dashboard}
      location={location}
      navigate={navigate}
      newClasses={newClasses}
      newSchoolGrades={newSchoolGrades}
      school={school}
      schoolCharacteristics={schoolCharacteristics}
      schoolGradesWithSolutions={schoolGradesWithSolutions}
      schoolSettings={schoolSettings}
      selectedFilterId={selectedFilterId}
      setFilterId={setFilterId}
      settings={settings}
      studentFlags={studentFlags}
      studentsData={studentsData}
      user={user}
    />
  )
}

export const StudentsPage = compose(
  graphql(userProfileQuery, {
    name: "userProfileQuery",
  }),
  graphql(schoolUploadsQuery, {
    options: () => ({
      fetchPolicy: "network-only",
      errorPolicy: "all",
      variables: { schoolId: getSchoolId() },
    }),
    name: "schoolUploadsQuery",
  }),
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(studentsQuery, {
    options: ({ settings }) => ({
      variables: {
        adminOnly: settings.adminOnlyRequests,
        schoolId: getSchoolId(),
      },
      fetchPolicy: "network-only",
    }),
    name: "studentsQuery",
  }),
  graphql(dashboardQuery, {
    skip: () => getSchoolId() === null,
    options: ({ settings }) => ({
      variables: {
        adminOnly: settings.adminOnlyRequests,
        schoolId: getSchoolId(),
      },
      fetchPolicy: "network-only",
    }),
    name: "dashboardQuery",
  }),
  graphql(characteristicsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    name: "characteristicsQuery",
  }),
  graphql(schoolSettingsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    name: "schoolSettingsQuery",
  }),
  graphql(activeCurrentClassesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    name: "activeCurrentClassesQuery",
  }),
  graphql(newClassesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    name: "newClassesQuery",
  }),
  graphql(schoolWithSisQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    name: "schoolWithSisQuery",
  }),
  graphql(schoolGradesWithSolutionsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    name: "schoolGradesWithSolutionsQuery",
  }),
  graphql(currentSchoolGradesQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        includeNew: true,
        includeLeaving: true,
      },
      fetchPolicy: "network-only",
    }),
    name: "currentSchoolGradesQuery",
  }),
  graphql(newSchoolGradesQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        includeNew: true,
        includeLeaving: true,
      },
      fetchPolicy: "network-only",
    }),
    name: "newSchoolGradesQuery",
  }),
  graphql(studentFlagsClientQuery, {
    props: ({ data: { studentFlags } }) => ({
      studentFlags,
    }),
  }),
  graphql(createOrUpdateInactiveStudentMutation, {
    name: "createOrUpdateInactiveStudentMutation",
  }),
  graphql(bulkUploadStudentsMutation, {
    name: "bulkUploadStudentsMutation",
  })
)(StudentsPageComponent)
