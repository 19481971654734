import React from "react"
import { Modal } from "reactstrap"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { Query } from "@apollo/client/react/components"
import compose from "lodash.flowright"
import {
  studentQuery,
  studentNameQuery,
  createOrUpdateStudentMutation,
  deleteStudentMutation,
  updateStudentCharacteristicResponses,
  updateInsensitiveStudentCharacteristicResponses,
  updateStudentEditFlag,
} from "domains/students/graphql"
import { teachersInfoQuery } from "domains/teachers/graphql"
import {
  studentRequestMutation,
  teacherRequestMutation,
  deleteConstraintsStudent,
  deleteConstraintsTeacher,
  requestQuery,
} from "domains/requests/graphql"
import { createOrUpdateConstraintsFriend } from "domains/friendships/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import {
  schoolSettingsQuery,
  settingsClientQuery,
} from "domains/accountSettings/graphql"
import {
  newClassesQuery,
  currentSchoolGradesQuery,
  newSchoolGradesQuery,
} from "domains/classes/graphql"
import { characteristicsQuery } from "domains/characteristics/graphql"

import { Loader, QueryError, UnsavedChangesModal } from "components"

import {
  Characteristics,
  Friendships,
  Requests,
  StudentDetails,
} from "domains/students/components"

import { handleSessionExpired, getSchoolId } from "util/app"
import { fullName } from "util/nameUtil"

class StudentModalComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      studentDetailsEdit: false,
      characteristicsEdit: false,
      friendshipsEdit: false,
      requestsEdit: false,
      cautionModal: false,
    }
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    handleSessionExpired(nextProps.error)
    handleSessionExpired(nextProps.errorNewClasses)
  }

  onToggle = () => {
    if (this.isEditing()) {
      this.setState({ cautionModal: true })
    } else {
      this.props.toggle()
    }
  }

  onIterateStudent = iterateStudent => () => {
    if (this.isEditing()) {
      this.setState({ cautionModal: true })
    } else {
      iterateStudent()
    }
  }

  toggleCautionModal = () => {
    this.setState({ cautionModal: !this.state.cautionModal })
  }

  resetEditing = () => {
    this.setState({
      cautionModal: false,
      studentDetailsEdit: false,
      characteristicsEdit: false,
      friendshipsEdit: false,
      requestsEdit: false,
    })
  }

  isEditing = () => {
    return (
      this.state.studentDetailsEdit ||
      this.state.characteristicsEdit ||
      this.state.friendshipsEdit ||
      this.state.requestsEdit
    )
  }

  toggleEdit = editKey => () =>
    this.setState(prevState => ({ [editKey]: !prevState[editKey] }))

  setEdit = editKey => value => {
    if (this.state[editKey] !== value) {
      this.setState({ [editKey]: value })
    }
  }

  render() {
    const {
      isOpen,
      isTeacher,
      teacherRequestsEditable,
      studentRequestsEditable,
      studentEditable,
      friendshipEditable,
      characteristicsEditable,
      studentId,
      showTeacherRequests,
      showStudentRequests,
      showCharacteristics,
      showFriendships,
      nextStudent,
      prevStudent,
      currentClasses,
      newClasses,
      currentSchoolGrades,
      newSchoolGrades,
      students,
      teachers,
      schoolCharacteristics,
      schoolSettings,
      settings,
      myprofile,
      client,
      createOrUpdateStudentMutation,
      createOrUpdateConstraintsFriend,
      deleteStudentMutation,
      studentRequestMutation,
      teacherRequestMutation,
      deleteConstraintsStudent,
      deleteConstraintsTeacher,
      updateStudentEditFlag,
      updateStudentCharacteristicResponses,
      updateInsensitiveStudentCharacteristicResponses,
      refetchQueries,
      deleteRefetchQueries = [],
      loadingOne,
      loadingTwo,
      loadingThree,
      loadingFour,
      loadingSix,
      loadingSeven,
      loadingEight,
      loadingNine,
    } = this.props

    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          className="c-student-modal modal-dialog-centered">
          {prevStudent ? (
            <i
              className="c-student-modal__icon c-student-modal__icon--prev fa fa-chevron-left"
              onClick={this.onIterateStudent(prevStudent)}
            />
          ) : null}
          {nextStudent ? (
            <i
              className="c-student-modal__icon c-student-modal__icon--next fa fa-chevron-right"
              onClick={this.onIterateStudent(nextStudent)}
            />
          ) : null}

          <Query
            client={client}
            query={studentQuery}
            variables={{ id: studentId, adminOnly: settings.adminOnlyRequests }}
            key={studentId}>
            {({ loading, error, data, refetch }) => {
              if (
                loading ||
                loadingOne ||
                loadingTwo ||
                loadingThree ||
                loadingFour ||
                loadingSix ||
                loadingSeven ||
                loadingEight ||
                loadingNine
              ) {
                return <Loader />
              }
              if (error) return <QueryError error={error} refetch={refetch} />
              const {
                id,
                characteristicResponses,
                friends,
                teacherConstraints,
                studentConstraints,
              } = data.student

              const requests =
                teacherConstraints &&
                teacherConstraints.concat(studentConstraints)

              return (
                <div className="c-student-modal__modal">
                  <StudentDetails
                    toggle={this.onToggle}
                    student={data.student}
                    refetchQueries={refetchQueries}
                    deleteRefetchQueries={deleteRefetchQueries}
                    currentSchoolGrades={currentSchoolGrades}
                    newSchoolGrades={newSchoolGrades}
                    currentClasses={currentClasses}
                    newClasses={newClasses}
                    createOrUpdateStudentMutation={
                      createOrUpdateStudentMutation
                    }
                    deleteStudentMutation={deleteStudentMutation}
                    updateStudentEditFlag={updateStudentEditFlag}
                    toggleEdit={this.toggleEdit("studentDetailsEdit")}
                    editable={studentEditable}
                    isTeacher={isTeacher}
                  />
                  <div className="c-student-modal__modal__body">
                    {showCharacteristics && (
                      <Characteristics
                        schoolCharacteristics={schoolCharacteristics}
                        updateStudentCharacteristicResponses={
                          isTeacher
                            ? updateInsensitiveStudentCharacteristicResponses
                            : updateStudentCharacteristicResponses
                        }
                        studentResponses={characteristicResponses}
                        studentId={id}
                        refetchQueries={refetchQueries}
                        updateStudentEditFlag={updateStudentEditFlag}
                        toggleEdit={this.toggleEdit("characteristicsEdit")}
                        editable={characteristicsEditable}
                      />
                    )}
                    {showFriendships && (
                      <Friendships
                        studentId={studentId}
                        friends={friends}
                        students={students}
                        createOrUpdateConstraintsFriend={
                          createOrUpdateConstraintsFriend
                        }
                        refetchQueries={refetchQueries}
                        maxFriends={schoolSettings.maxFriends}
                        updateStudentEditFlag={updateStudentEditFlag}
                        toggleEdit={this.toggleEdit("friendshipsEdit")}
                        editable={friendshipEditable}
                      />
                    )}
                    {(showStudentRequests || showTeacherRequests) && (
                      <Requests
                        studentId={id}
                        requests={requests}
                        teacherRequestsEditable={teacherRequestsEditable}
                        studentRequestsEditable={studentRequestsEditable}
                        students={students}
                        teachers={teachers}
                        inputByName={fullName(myprofile)}
                        refetchQueries={refetchQueries.concat([
                          {
                            query: requestQuery,
                            variables: {
                              adminOnly: settings.adminOnlyRequests,
                              schoolId: getSchoolId(),
                            },
                          },
                        ])}
                        studentRequestMutation={studentRequestMutation}
                        teacherRequestMutation={teacherRequestMutation}
                        deleteConstraintsStudent={deleteConstraintsStudent}
                        deleteConstraintsTeacher={deleteConstraintsTeacher}
                        updateStudentEditFlag={updateStudentEditFlag}
                        adminOnlySetting={settings.adminOnlyRequests}
                        toggleEdit={this.toggleEdit("requestsEdit")}
                        setEdit={this.setEdit("requestsEdit")}
                      />
                    )}
                  </div>
                </div>
              )
            }}
          </Query>
        </Modal>
        {this.state.cautionModal && (
          <UnsavedChangesModal
            isOpen
            toggle={this.toggleCautionModal}
            heading="Unsaved Changes"
            text="Your changes have not been saved, are you sure you want to leave?"
            onDontSaveButtonClick={() => {
              this.resetEditing()
              this.props.toggle()
            }}
          />
        )}
      </React.Fragment>
    )
  }
}

export const StudentModal = compose(
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(studentNameQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, students, error } }) => ({
      loadingOne: loading,
      students,
      error,
    }),
  }),
  graphql(teachersInfoQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, teachers } }) => ({
      loadingTwo: loading,
      teachers,
    }),
  }),
  graphql(schoolSettingsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolSettings } }) => ({
      loadingFour: loading,
      schoolSettings,
    }),
  }),
  graphql(newClassesQuery, {
    skip: ({ showTeacherRequests, showStudentRequests }) =>
      !(showTeacherRequests || showStudentRequests),
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, error, newClasses } }) => ({
      loadingSix: loading,
      errorNewClasses: error,
      newClasses,
    }),
  }),
  graphql(characteristicsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolCharacteristics } }) => ({
      loadingSeven: loading,
      schoolCharacteristics,
    }),
  }),
  graphql(currentSchoolGradesQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        includeNew: true,
        includeLeaving: false,
      },
    }),
    props: ({ data: { loading, currentSchoolGrades } }) => ({
      loadingEight: loading,
      currentSchoolGrades,
    }),
  }),
  graphql(newSchoolGradesQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        includeNew: true,
        includeLeaving: true,
      },
    }),
    props: ({ data: { loading, newSchoolGrades } }) => ({
      loadingNine: loading,
      newSchoolGrades,
    }),
  }),
  graphql(userProfileQuery, {
    props: ({ data: { myprofile } }) => ({
      myprofile,
    }),
  }),
  graphql(createOrUpdateStudentMutation, {
    name: "createOrUpdateStudentMutation",
  }),
  graphql(teacherRequestMutation, {
    name: "teacherRequestMutation",
  }),
  graphql(studentRequestMutation, {
    name: "studentRequestMutation",
  }),
  graphql(deleteConstraintsStudent, {
    name: "deleteConstraintsStudent",
  }),
  graphql(deleteConstraintsTeacher, {
    name: "deleteConstraintsTeacher",
  }),
  graphql(createOrUpdateConstraintsFriend, {
    name: "createOrUpdateConstraintsFriend",
  }),
  graphql(updateStudentCharacteristicResponses, {
    name: "updateStudentCharacteristicResponses",
  }),
  graphql(updateInsensitiveStudentCharacteristicResponses, {
    name: "updateInsensitiveStudentCharacteristicResponses",
  }),
  graphql(deleteStudentMutation, { name: "deleteStudentMutation" }),
  graphql(updateStudentEditFlag, { name: "updateStudentEditFlag" })
)(withApollo(StudentModalComponent))
