import React from "react"
import {
  studentsQuery,
  studentQuery,
  deleteStudentNotesByCurrentGradesMutation,
  removeStudentsByCurrentGradesMutation,
} from "domains/students/graphql"

import { deleteConstraintsFriendsByCurrentGradesMutation } from "domains/friendships/graphql"

import { deleteRequestConstraintsByCurrentGrade } from "domains/requests/graphql"

import { deleteStudentCharacteristicResponsesByCurrentGradeMutation } from "domains/characteristics/graphql"

import { dashboardQuery } from "domains/dashboard/graphql"
import {
  activeCurrentClassesQuery,
  schoolGradesWithSolutionsQuery,
} from "domains/classes/graphql"
import {
  ActionBar,
  SuccessModal,
  ColdStart,
  StudentFilterInput,
} from "components"
import {
  AddModal,
  StudentModal,
  SortableStudentsTable,
  RolledOverNote,
  RemoveCharacteristicResponsesModal,
  StudentHistoriesModal,
} from "domains/students/components"
import { Button } from "reactstrap"

import {
  ExportByGradesModal,
  RemoveStudentsByCurrentClassesModal,
  RemoveByCurrentGradesModal,
  RenameCurrentClassModal,
  UpdateNewGradeModal,
} from "domains/accountSettings/components"

import { validateStudent } from "util/validators"
import { handleSessionExpired, getSchoolId } from "util/app"

import { AccountTypeContext } from "config/accountTypeContext"
import studentsTemplate from "templateFiles/students_template.csv"

import { STUDENT_CODE } from "domains/students/errorFields"
import { NEW_GRADE } from "domains/app/constants/gradeFields"
import { warnings } from "domains/students/upload/warnings"
import { sortStudentsByClass } from "util/sortUtil"
import { downloadAuthenticatedFile } from "util/downloadUtil"
import { getApiBaseUri } from "util/apiUtil"
import { Auth0Context } from "domains/auth/auth0Wrapper"
import { CleverSync, PowerschoolSync, SftpSync } from "domains/sis/components"
import { getNotificationsQuery } from "domains/notifications/graphql"

import { isSystemGenerated } from "util/studentUtil"
import { defaultNewGradeForStudent, isSpecialGrade } from "util/gradeUtil"

import {
  INSTRUCTION_ADD_STUDENTS_PLAYER_URL_1,
  INSTRUCTION_ADD_STUDENTS_PLAYER_URL_2,
} from "constants/videos"

const initialStudent = currentSchoolGrades => {
  const currentGrade = currentSchoolGrades[0]
  const newGrade = defaultNewGradeForStudent(currentGrade)

  return {
    firstName: "",
    lastName: "",
    gender: "",
    currentClass: "",
    currentGrade: currentGrade ? currentGrade.code : "",
    newGrade: newGrade ? newGrade.code : "",
    studentCode: "",
  }
}

const getFirstNonEmptyFilterInList = (filters, studentsData) => {
  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]
    const filteredStudents = studentsData.filter(filter.filterFunction)
    if (filteredStudents.length > 0) {
      return filter
    }
  }

  return filters[0]
}

class StudentsComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allGradesRendering: false,
      studentModal: false,
      successModal: false,
      addModal: false,
      studentId: "",
      searchValue: "",
      addStudentDetails: initialStudent(this.props.currentSchoolGrades),
      errors: {},
      displayBulkUploadScreen: false,
      displayPowerschoolSyncScreen: false,
      displayCleverSyncScreen: false,
      showDeleteStudentCharacteristicResponses: false,
      showDeleteAllConstraintsFriends: false,
      showDeleteAllRequestConstraints: false,
      showDeleteAllStudentNotes: false,
      showRemoveStudentsByGrade: false,
      showRemoveStudentsByClass: false,
      showDownloadStudentHistories: false,
      showRenameCurrentClass: false,
      showUpdateNewGrade: false,
      showExportStudentsByGrade: false,
    }
  }

  componentDidUpdate() {
    const { allGradesRendering } = this.state
    if (allGradesRendering) {
      // the timeout allows for the loading message to display
      setTimeout(() => {
        this.setState({
          allGradesRendering: false,
        })
      }, 200)
    }
  }

  // Refetch queries for delete student mutation
  deleteRefetchQueries = [
    {
      query: dashboardQuery,
      variables: {
        schoolId: getSchoolId(),
        adminOnly: this.props.settings.adminOnlyRequests,
      },
    },
    {
      query: getNotificationsQuery,
      variables: { schoolId: getSchoolId() },
    },
    {
      query: schoolGradesWithSolutionsQuery,
      variables: { schoolId: getSchoolId() },
    },
    {
      query: studentsQuery,
      variables: {
        adminOnly: this.props.settings.adminOnlyRequests,
        schoolId: getSchoolId(),
      },
    },
    {
      query: activeCurrentClassesQuery,
      variables: {
        schoolId: getSchoolId(),
      },
    },
  ]

  UNSAFE_componentWillReceiveProps = nextProps => {
    handleSessionExpired(nextProps.error)
  }

  onAddRequestClick = studentId => {
    this.setState({
      studentModal: true,
      studentId: studentId,
    })
  }

  toggle = () => {
    this.setState({
      studentModal: !this.state.studentModal,
    })
  }

  closeSuccessModal = () => {
    // On close open the student modal
    this.setState({
      successModal: false,
      studentModal: true,
    })
  }

  toggleAdd = () => {
    this.setState({
      addModal: !this.state.addModal,
      errors: {},
    })
  }

  onSuccessfulAdd = studentId => {
    this.setState({
      studentId: studentId,
      addModal: false,
      successModal: true,
      addStudentDetails: initialStudent(this.props.currentSchoolGrades), // Clear the students details from Add Student Modal Inputs
    })
  }

  onFilterChange = e => {
    this.props.setFilterId(e.target.value)
    if (e.target.value === "all-grades") {
      this.setState({
        allGradesRendering: true,
      })
    }
  }

  onChange = e => {
    const { addStudentDetails } = this.state
    if (e.target.name === "currentGrade") {
      const { currentSchoolGrades } = this.props
      // get the selected grade
      const currentGrade = currentSchoolGrades.find(
        grade => grade.code === e.target.value
      )
      const newGrade = defaultNewGradeForStudent(
        currentGrade,
        addStudentDetails
      )

      this.setState({
        addStudentDetails: {
          ...addStudentDetails,
          // clear the currentClass
          currentClass: "",
          newGrade: newGrade ? newGrade.code : "",
          // clear new class
          newClass: "",
          currentGrade: e.target.value,
        },
      })
    } else {
      // Update Student
      this.setState({
        addStudentDetails: {
          ...addStudentDetails,
          // clear the newClass if newGrade is changed
          newClass:
            e.target.name === "newGrade" ? "" : addStudentDetails.newClass,
          [e.target.name]: e.target.value,
        },
      })
    }
  }

  onCurrentClassChange = e => {
    const { addStudentDetails } = this.state
    if (e === null || e.label === "N/A") {
      this.setState({
        addStudentDetails: {
          ...addStudentDetails,
          currentClass: "",
        },
      })
    } else {
      this.setState({
        addStudentDetails: {
          ...addStudentDetails,
          currentClass: e.label,
        },
      })
    }
  }

  onGenderChange = e => {
    this.setState({
      addStudentDetails: {
        ...this.state.addStudentDetails,
        gender: e.target.value,
      },
    })
  }

  onSearchChange = e => {
    if (e === undefined) {
      this.setState({
        searchValue: "",
      })
    } else {
      this.setState({
        searchValue: e.target.value,
      })
    }
  }

  onRowClick = studentId => {
    if (studentId) {
      this.setState({
        studentId,
        studentModal: true,
      })
    } else {
      // if clicking on empty row show the add student modal
      this.toggleAdd()
    }
  }

  addStudent = () => {
    const { createOrUpdateInactiveStudentMutation } = this.props
    const { addStudentDetails } = this.state

    // We don't bother retrieving the new students details
    // as it will be loaded when we select that student
    const refetchQueries = this.deleteRefetchQueries

    // Check that all fields are valid
    const errors = validateStudent(addStudentDetails)
    this.setState({ errors })

    // Do Mutation If fields are valid
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })
      const student = {
        ...addStudentDetails,
        newClass:
          addStudentDetails.newClass === ""
            ? undefined
            : addStudentDetails.newClass,
        schoolId: getSchoolId(),
      }
      // build variables
      const variables = { student }

      createOrUpdateInactiveStudentMutation({ variables, refetchQueries })
        .then(({ data }) => {
          this.setState({ loading: false })

          this.onSuccessfulAdd(data.createOrUpdateInactiveStudent.id)
        })
        .catch(error => {
          handleSessionExpired(error)

          if (error.toString().includes(STUDENT_CODE.key)) {
            this.setState({
              loading: false,
              errors: { studentId: STUDENT_CODE.message },
            })
          } else {
            this.setState({
              loading: false,
              errors: {
                mutation: "Network Error, Could Not Save Student",
              },
            })
          }
        })
    }
  }

  navToCharacteristics = () => {
    const { navigate } = this.props
    navigate("/Characteristics", { state: { navFrom: "/Students" } })
  }

  handleNavToStudentsClick = () => {}

  csvExport = async gradeIds => {
    const { settings, user } = this.props
    const token = await this.context.getTokenSilently()
    const schoolId = getSchoolId()
    const userRole = user.role

    if (!schoolId || !userRole || !token) {
      return
    }

    const gradeIdsParam = gradeIds.join(",")

    const baseUrl = getApiBaseUri()
    const filterSensitive = settings.adminOnlyRequests ? "false" : "true"
    const url = `${baseUrl}/export_student_data/${schoolId}?grade_ids=${gradeIdsParam}&filter_sensitive=${filterSensitive}`

    const response = await downloadAuthenticatedFile(token, url)

    if (response.ok) {
      this.setState({ showExportStudentsByGrade: false })
    } else {
      const message = await response.text()
      handleSessionExpired({ message })
      this.setState({
        errors: { export: "Error exporting students" },
      })
    }
  }

  static contextType = Auth0Context

  render() {
    const {
      studentsData = [],
      schoolSettings = {},
      settings,
      currentSchoolGrades,
      newSchoolGrades,
      selectedFilterId,
      currentClasses,
      newClasses,
      schoolCharacteristics,
      studentFlags,
      bulkUploadStudentsMutation,
      school,
      dashboard,
    } = this.props

    const {
      loading,
      allGradesRendering,
      studentId,
      errors,
      addStudentDetails,
      studentModal,
      successModal,
      addModal,
      searchValue,
      displayBulkUploadScreen,
      displayCleverSyncScreen,
      displayPowerschoolSyncScreen,
      displaySftpSyncScreen,
      showDownloadStudentHistories,
      showRenameCurrentClass,
      showUpdateNewGrade,
      showDeleteStudentCharacteristicResponses,
      showDeleteAllConstraintsFriends,
      showDeleteAllRequestConstraints,
      showDeleteAllStudentNotes,
      showRemoveStudentsByGrade,
      showRemoveStudentsByClass,
      showExportStudentsByGrade,
    } = this.state

    // Refetch queries for create/edit student mutation
    const refetchQueries = this.deleteRefetchQueries.concat([
      {
        query: studentQuery,
        variables: {
          id: studentId,
          adminOnly: settings.adminOnlyRequests,
        },
      },
    ])

    const togglePowerschoolSync = () =>
      this.setState(prevState => ({
        displayPowerschoolSyncScreen: !prevState.displayPowerschoolSyncScreen,
      }))

    const toggleCleverSync = () =>
      this.setState(prevState => ({
        displayCleverSyncScreen: !prevState.displayCleverSyncScreen,
      }))

    const toggleSftpSync = () =>
      this.setState(prevState => ({
        displaySftpSyncScreen: !prevState.displaySftpSyncScreen,
      }))

    const toggleBulkUploadScreen = () => {
      this.setState(({ displayBulkUploadScreen }) => ({
        displayBulkUploadScreen: !displayBulkUploadScreen,
      }))
    }

    const showBulkUploadScreen = () => {
      this.setState({ displayBulkUploadScreen: true })
    }

    if (displayPowerschoolSyncScreen) {
      return (
        <PowerschoolSync
          school={school}
          toggleSync={togglePowerschoolSync}
          refetchQueries={refetchQueries}
        />
      )
    }

    if (displaySftpSyncScreen) {
      return (
        <SftpSync
          school={school}
          toggleSync={toggleSftpSync}
          refetchQueries={refetchQueries}
        />
      )
    }

    if (displayCleverSyncScreen) {
      return (
        <CleverSync
          school={school}
          toggleSync={toggleCleverSync}
          refetchQueries={refetchQueries}
        />
      )
    }

    const schoolHasBeenRolledOver =
      dashboard.studentCount === 0 && dashboard.inactiveStudentCount > 50

    const cursorClass = allGradesRendering ? "cursor-wait" : ""
    if (studentsData.length < 1 || displayBulkUploadScreen) {
      return (
        <>
          {schoolHasBeenRolledOver && <RolledOverNote />}
          <ColdStart
            type="Students"
            title="Import Students"
            step1="1. Download and complete the students template"
            backText="< Back to Students page"
            file={studentsTemplate}
            fileUploadMutation={bulkUploadStudentsMutation}
            knownWarnings={warnings}
            nextStep="/Students/Upload/Columns"
            toggleBulkUploadScreen={toggleBulkUploadScreen}
            displayBulkUploadScreen={displayBulkUploadScreen}
            requiredHeaders="Student ID,First Name,Surname,Gender,Current Grade,Current Class"
            videoUrl={INSTRUCTION_ADD_STUDENTS_PLAYER_URL_1}
            videoUrl2={INSTRUCTION_ADD_STUDENTS_PLAYER_URL_2}
            videoDescriptionText={"Importing students for the first time?"}
            videoDescriptionText2={
              "Adding new enrolments or additional grades?"
            }
            videoOpenText={"Watch Video"}
            videoOpenText2={"Watch Video"}>
            {(school.powerschoolSisConfig ||
              school.sftpSisConfig ||
              school.cleverSisConfig) &&
              !displayBulkUploadScreen && (
                <>
                  <h2 className="m-2">
                    ...or Import from a Student Information System
                  </h2>
                  <div className="d-flex">
                    {school.powerschoolSisConfig && (
                      <Button className="mx-2" onClick={togglePowerschoolSync}>
                        Import from PowerSchool
                      </Button>
                    )}
                    {school.sftpSisConfig && (
                      <Button className="mx-2" onClick={toggleSftpSync}>
                        Import from SFTP Server
                      </Button>
                    )}
                    {school.cleverSisConfig && (
                      <Button className="mx-2" onClick={toggleCleverSync}>
                        Import from Clever
                      </Button>
                    )}
                  </div>
                </>
              )}
          </ColdStart>
        </>
      )
    }

    const adminMode = settings.adminOnlyRequests

    const getNameAsStringFromStudent = student => {
      const studentFirstName = student.firstName.toLowerCase()
      const studentLastName = student.lastName.toLowerCase()
      return `${studentFirstName} ${studentLastName}`
    }

    // Count how many times a student name appears at the beginning rather than doing it each time the potential-duplicate-students is utilised
    const numberOfTimesStudentNameAppearsObject = studentsData.reduce(
      (acc, student) => {
        const studentNameKey = getNameAsStringFromStudent(student)

        const currentCount = acc[studentNameKey]

        if (currentCount) {
          acc[studentNameKey] = currentCount + 1
        } else {
          acc[studentNameKey] = 1
        }
        return acc
      },
      {}
    )

    // Build available filters
    const availableFilters = currentSchoolGrades
      .filter(grade => !isSpecialGrade(grade))
      .flatMap(grade => {
        // Filter students by their current grade
        return [
          {
            id: `gradeId-${grade.id}`,
            label: grade.label,
            value: grade.id,
            filterFunction: student => student.currentGrade.id === grade.id,
          },
        ]
      })
      .concat([
        { id: "all-grades", label: "All Grades", filterFunction: () => true },
        {
          id: "new-students",
          label: "New Students",
          filterFunction: student => {
            return (
              student.currentClass === null ||
              student.currentClass.label.startsWith("New_") ||
              student.currentGrade.code === NEW_GRADE
            )
          },
        },
        {
          id: "unallocated-students",
          label: "Unallocated Students",
          filterFunction: student => {
            const schoolGradeIdsWithSolution =
              this.props.schoolGradesWithSolutions.map(({ id }) => id)
            return (
              !student.newClass &&
              schoolGradeIdsWithSolution.includes(student.newGrade.id)
            )
          },
        },
        {
          id: "missing-ids",
          label: "Students with no IDs",
          filterFunction: student => isSystemGenerated(student.studentCode),
        },
        {
          id: "students-leaving",
          label: "Students Leaving",
          filterFunction: student => {
            return student.newGrade.code === "LEAVING"
          },
        },
        {
          id: "potential-duplicate-students",
          label: "Potential duplicate students",
          filterFunction: student => {
            const studentNameKey = getNameAsStringFromStudent(student)

            const numberOfTimesStudentNameAppears =
              numberOfTimesStudentNameAppearsObject[studentNameKey]
            if (!numberOfTimesStudentNameAppears) {
              return false
            }

            return numberOfTimesStudentNameAppears > 1
          },
        },
      ])

    const filter =
      availableFilters.find(({ id }) => id === selectedFilterId) ||
      getFirstNonEmptyFilterInList(availableFilters, studentsData)

    const filterComponent = (
      <StudentFilterInput
        onChange={this.onFilterChange}
        value={filter.id}
        options={availableFilters}
      />
    )

    const filterBySearchValue = (studentData, searchValue) => {
      if (searchValue === "") {
        return studentData
      }
      const lowerSearchValue = searchValue.toLowerCase()
      // searching of students using firstName, lastName, and student Code
      return studentData.filter(student => {
        const searchString = [
          student.firstName,
          student.lastName,
          student.studentCode,
        ]
          .join(" ")
          .toLowerCase()
        return searchString.includes(lowerSearchValue)
      })
    }

    // check if student has been deleted as async refresh of all students is too slow
    const isDeleted = student => studentFlags.deletedStudent === student.id

    const students = filterBySearchValue(
      studentsData.filter(filter.filterFunction),
      searchValue
    ).filter(student => !isDeleted(student))

    sortStudentsByClass(students)

    let additionalImportActions = []
    let additionalRemoveActions = []
    let additionalActions = []

    additionalImportActions.push({
      onClick: () => {
        this.props.navigate("/Students/Characteristics/Upload")
      },
      text: "Bulk Import Characteristic",
      icon: "fa-upload",
    })

    additionalImportActions.push({
      onClick: () => {
        this.props.navigate("/Students/FriendshipPreferences/Upload")
      },
      text: "Bulk Import Friendship Preferences",
      icon: "fa-upload",
    })

    additionalImportActions.push({
      onClick: () => {
        this.props.navigate("/Students/StudentNotes/Upload")
      },
      text: "Bulk Import Student Notes",
      icon: "fa-upload",
    })

    if (school.powerschoolSisConfig) {
      additionalImportActions.push({
        onClick: () => this.setState({ displayPowerschoolSyncScreen: true }),
        text: "Sync from Powerschool",
        icon: "fa-refresh",
      })
    }

    if (school.sftpSisConfig) {
      additionalImportActions.push({
        onClick: () => this.setState({ displaySftpSyncScreen: true }),
        text: "Sync from SFTP Server",
        icon: "fa-refresh",
      })
    }

    if (school.cleverSisConfig) {
      additionalImportActions.push({
        onClick: () => this.setState({ displayCleverSyncScreen: true }),
        text: "Sync from Clever",
        icon: "fa-refresh",
      })
    }

    additionalActions.push({
      onClick: () => this.setState({ showExportStudentsByGrade: true }),
      text: "Export Students",
      icon: "fa-download",
    })

    additionalActions.push({
      onClick: () => this.setState({ showDownloadStudentHistories: true }),
      text: "Download Student History",
      icon: "fa-download",
    })

    additionalActions.push({
      onClick: () => this.setState({ showRenameCurrentClass: true }),
      text: "Rename Current Class",
      icon: "fa-pencil",
    })

    additionalActions.push({
      onClick: () => this.setState({ showUpdateNewGrade: true }),
      text: `Update ${this.props.gettextObj.gettext("New Grade")}`,
      icon: "fa-refresh",
    })

    additionalRemoveActions.push({
      onClick: () =>
        this.setState({ showDeleteStudentCharacteristicResponses: true }),
      text: "Remove Characteristic Responses",
      icon: "fa-trash",
    })

    additionalRemoveActions.push({
      onClick: () => this.setState({ showDeleteAllConstraintsFriends: true }),
      text: "Remove Friendship Preferences",
      icon: "fa-trash",
    })

    additionalRemoveActions.push({
      onClick: () => this.setState({ showDeleteAllRequestConstraints: true }),
      text: "Remove Requests",
      icon: "fa-trash",
    })

    additionalRemoveActions.push({
      onClick: () => this.setState({ showDeleteAllStudentNotes: true }),
      text: "Remove Student Notes",
      icon: "fa-trash",
    })

    additionalRemoveActions.push({
      onClick: () => this.setState({ showRemoveStudentsByGrade: true }),
      text: "Remove Students by Grade",
      icon: "fa-trash",
    })

    additionalRemoveActions.push({
      onClick: () => this.setState({ showRemoveStudentsByClass: true }),
      text: "Remove Students by Class",
      icon: "fa-trash",
    })

    return (
      <div className={`c-students-page u-row-fix ${cursorClass}`}>
        <div>
          <ActionBar
            type="Students"
            addText="Add Student"
            bulkImportText="Bulk Import Students"
            onAddClick={this.toggleAdd}
            searchPlaceholder="Search student..."
            onSearchChange={this.onSearchChange}
            searchValue={searchValue}
            adminMode={adminMode}
            filterComponent={filterComponent}
            onImportClick={showBulkUploadScreen}
            additionalImportActions={additionalImportActions}
            additionalRemoveActions={additionalRemoveActions}
            additionalActions={additionalActions}
          />
          {allGradesRendering ? (
            <div className="w-100 mt-5 d-flex justify-content-center align-items-center">
              Loading all grade students, please wait...
            </div>
          ) : (
            <div>
              <SortableStudentsTable
                students={students}
                schoolCharacteristics={schoolCharacteristics}
                teacherRequestsEditable={true}
                studentRequestsEditable={true}
                maxFriends={schoolSettings.maxFriends}
                navToCharacteristics={this.navToCharacteristics}
                onAddRequestClick={this.onAddRequestClick}
                onRowClick={this.onRowClick}
              />

              {filter.id !== "all-grades" || searchValue !== "" ? (
                <div className="u-total-text ml-2 my-2">
                  {`Total - ${students.length}/${studentsData.length} Students`}
                </div>
              ) : (
                <div className="u-total-text ml-2 my-2">{`Total - ${studentsData.length} Students`}</div>
              )}
            </div>
          )}
        </div>
        <AddModal
          toggle={this.toggleAdd}
          isOpen={addModal}
          refetchQueries={this.refetchQueries}
          onSuccessfulAdd={this.onSuccessfulAdd}
          onChange={this.onChange}
          onCurrentClassChange={this.onCurrentClassChange}
          onGenderChange={this.onGenderChange}
          addStudent={this.addStudent}
          student={addStudentDetails}
          errors={errors}
          loading={loading}
          newClasses={newClasses}
          currentSchoolGrades={currentSchoolGrades}
          newSchoolGrades={newSchoolGrades}
          currentClasses={currentClasses}
          onBulkUploadClick={showBulkUploadScreen}
        />
        <SuccessModal
          isOpen={successModal}
          toggle={this.closeSuccessModal}
          heading="Add Student Success"
          text="Student was successfully added"
        />
        <StudentModal
          isOpen={studentModal}
          toggle={this.toggle}
          studentId={studentId}
          showTeacherRequests
          showStudentRequests
          teacherRequestsEditable
          studentRequestsEditable
          studentEditable
          showFriendships
          friendshipEditable
          showCharacteristics
          characteristicsEditable
          currentSchoolGrades={currentSchoolGrades}
          newSchoolGrades={newSchoolGrades}
          currentClasses={currentClasses}
          refetchQueries={refetchQueries}
          deleteRefetchQueries={this.deleteRefetchQueries}
        />

        {showDeleteStudentCharacteristicResponses && (
          <RemoveCharacteristicResponsesModal
            schoolId={getSchoolId()}
            adminOnly={settings.adminOnlyRequests}
            characteristics={schoolCharacteristics}
            deleteStudentCharacteristicResponsesByCurrentGradeMutation={
              deleteStudentCharacteristicResponsesByCurrentGradeMutation
            }
            refetchQueries={this.deleteRefetchQueries}
            toggle={() =>
              this.setState({
                showDeleteStudentCharacteristicResponses: false,
              })
            }
          />
        )}

        {showDeleteAllConstraintsFriends && (
          <RemoveByCurrentGradesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({
                showDeleteAllConstraintsFriends: false,
              })
            }
            title="Remove Friendship Preferences"
            description="This function removes the Friendship Preferences for students in the Current Grade/s you have selected.  Students in Current Grade/s not selected will not be affected."
            cautionHeading="Remove Friendship Preferences"
            cautionText="You can not ‘undo’ the operation.  Friendship Nominations will be permanently deleted for students in selected Current Grade/s."
            removeButtonText="Remove Friends"
            cautionButtonText="Yes, Proceed"
            successMessage="Friendship Preferences by grade have been removed."
            errorMessage="Could not remove data"
            refetchQueries={this.deleteRefetchQueries}
            removeByGradesMutation={
              deleteConstraintsFriendsByCurrentGradesMutation
            }
          />
        )}

        {showDeleteAllRequestConstraints && (
          <RemoveByCurrentGradesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({
                showDeleteAllRequestConstraints: false,
              })
            }
            title="Remove Requests"
            description="This function removes the Requests for students in the Current Grade/s you have selected.  Students in Current Grade/s not selected will not be affected."
            cautionHeading="Remove Requests"
            cautionText="You can not ‘undo’ the operation.  Requests will be permanently deleted for students in selected Current Grade/s."
            removeButtonText="Remove Requests"
            cautionButtonText="Yes, Proceed"
            successMessage="Requests by grade have been removed."
            errorMessage="Could not remove data"
            refetchQueries={this.deleteRefetchQueries}
            removeByGradesMutation={deleteRequestConstraintsByCurrentGrade}
          />
        )}

        {showDeleteAllStudentNotes && (
          <RemoveByCurrentGradesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({
                showDeleteAllStudentNotes: false,
              })
            }
            title="Remove Student Notes"
            description="This function removes the Student Notes for students in the Current Grade/s you have selected.  Students in Current Grade/s not selected will not be affected.  "
            cautionHeading="Remove Student Notes"
            removeButtonText="Remove Notes"
            cautionText="You can not ‘undo’ the operation.  Student Notes will be permanently deleted for students in selected Current Grade/s."
            cautionButtonText="Yes, Proceed"
            successMessage="Students Notes by grade have been removed."
            errorMessage="Could not remove data"
            refetchQueries={this.deleteRefetchQueries}
            removeByGradesMutation={deleteStudentNotesByCurrentGradesMutation}
          />
        )}

        {showRemoveStudentsByGrade && (
          <RemoveByCurrentGradesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({
                showRemoveStudentsByGrade: false,
              })
            }
            title="Remove Students by Current Grade"
            description="This function removes students based on the Current Grade or grades that you select below. Students in grade/s not selected will not be affected."
            cautionHeading="Remove Students"
            removeButtonText="Remove Students"
            cautionText="You can not 'undo' this operation. Are you sure you would like to permanently remove all students by grade?"
            cautionButtonText="Yes, Proceed"
            successMessage="Students by grade have been removed."
            errorMessage="Could not remove data"
            refetchQueries={this.deleteRefetchQueries}
            removeByGradesMutation={removeStudentsByCurrentGradesMutation}
          />
        )}

        {showExportStudentsByGrade && (
          <ExportByGradesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({
                showExportStudentsByGrade: false,
                errors: { export: null },
              })
            }
            title="Export Students by Current Grade"
            description="This function exports students based on the Current Grade/s that you select below."
            primaryButtonText="Export Students"
            exportFunction={this.csvExport}
            errorMessage={this.state.errors.export}
          />
        )}

        {showRemoveStudentsByClass && (
          <RemoveStudentsByCurrentClassesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({
                showRemoveStudentsByClass: false,
              })
            }
            adminOnly={settings.adminOnlyRequests}
          />
        )}

        {showDownloadStudentHistories && (
          <StudentHistoriesModal
            toggle={() => {
              this.setState({
                showDownloadStudentHistories: false,
              })
            }}
          />
        )}

        {showRenameCurrentClass && (
          <RenameCurrentClassModal
            schoolId={getSchoolId()}
            toggle={() => {
              this.setState({
                showRenameCurrentClass: false,
              })
            }}
          />
        )}

        {showUpdateNewGrade && (
          <UpdateNewGradeModal
            schoolId={getSchoolId()}
            toggle={() => {
              this.setState({
                showUpdateNewGrade: false,
              })
            }}
            adminOnly={settings.adminOnlyRequests}
          />
        )}
      </div>
    )
  }
}

export const Students = props => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return <StudentsComponent gettextObj={gettextObj} {...props} />
}
