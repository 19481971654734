import React, { useState } from "react"

import { useNavigate } from "react-router-dom"

import { CleverModal } from "./cleverModal"

import { PowerschoolModal } from "./powerschoolModal"

import { isSuper } from "util/userUtil"

export const AdvancedFeatures = ({ school, myprofile }) => {
  const [showPowerschoolModal, setPowerschoolModal] = useState(false)
  const [showCleverModal, setCleverModal] = useState(false)
  const togglePowerschoolModal = () =>
    setPowerschoolModal(!showPowerschoolModal)

  const toggleCleverModal = () => setCleverModal(!showCleverModal)

  const navigate = useNavigate()

  return (
    <div id="advanced-features" className="u-content-border-bottom pb-5">
      <h2 className="mb-2 mt-5 u-blue-primary">Advanced Features</h2>
      <div className="mb-5">
        These features are generally used with the assistance of the Class
        Solver Support Team.
      </div>

      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <div className="c-misc-upload__description mb-2">
            Import Class Lists
          </div>
          <p className="c-misc-upload__description">
            Import a pre-allocated list of students into the Solver page based
            on the Student ID.
          </p>
        </div>
        <div
          className="c-button--secondary"
          onClick={() => navigate("/AccountSettings/ImportClassLists")}>
          Import Class Lists
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <div className="c-misc-upload__description mb-2">
            Transfer Student Requests
          </div>
          <p className="c-misc-upload__description">
            Export student requests from an account and then import them into a
            different account.
          </p>
        </div>
        <div
          className="c-button--secondary"
          onClick={() => {
            navigate("/AccountSettings/TransferStudentRequests")
          }}>
          Transfer Student Requests
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <div className="c-misc-upload__description mb-2">
            Transfer Teacher Requests
          </div>
          <p className="c-misc-upload__description">
            Export teacher requests from an account and then import them into a
            different account.
          </p>
        </div>
        <div
          className="c-button--secondary"
          onClick={() => {
            navigate("/Requests/TeacherRequests/Transfer")
          }}>
          Transfer Teacher Requests
        </div>
      </div>

      {isSuper(myprofile.role) && (
        <div className="d-flex justify-content-between mb-4 align-items-center">
          <div>
            <div className="c-misc-upload__description mb-2">
              Power School Integration
            </div>
            <p className="c-misc-upload__description">
              Schools that use the Power School Student Information System can
              import their students details directly without using a CSV.
            </p>
          </div>
          <div className="c-button--secondary" onClick={togglePowerschoolModal}>
            Configure PowerSchool
          </div>
        </div>
      )}

      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <div className="c-misc-upload__description mb-2">
            Clever Integration
          </div>
          <p className="c-misc-upload__description">
            Schools that use the Clever Student Information System can import
            their students details directly without using a CSV.
          </p>
        </div>
        <div className="c-button--secondary" onClick={toggleCleverModal}>
          Configure Clever
        </div>
      </div>

      {showPowerschoolModal && (
        <PowerschoolModal toggle={togglePowerschoolModal} school={school} />
      )}

      {showCleverModal && (
        <CleverModal toggle={toggleCleverModal} school={school} />
      )}
    </div>
  )
}
